module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.10.0__eslint-import_yaxxqbhnz4qygyi5f5wsjmh4tu/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#39a4f2","showSpinner":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.10.0__eslint_rk7m5litnugp4omiyahdcoxelu/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"maintainCase":false,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-typography@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.10.0__eslint-impor_br67butj7aalopedfcltpc72ta/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/config/typography"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.10.0__eslint-import-_btmgyqgidqgepdygo2wyjbsshu/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Litten","short_name":"Litten","start_url":"/","background_color":"#fafafa","theme_color":"#39a4f2","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7d98af63ad7855be47f1d9f7986ac544"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.10.0__eslint-import-resolver-typescript@3.6.3_@typ_lkjmfj4smxfoeoac3pl6gj7s2m/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
