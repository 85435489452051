exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-beta-tsx": () => import("./../../../src/pages/join-beta.tsx" /* webpackChunkName: "component---src-pages-join-beta-tsx" */),
  "component---src-pages-open-verification-tsx": () => import("./../../../src/pages/open/verification.tsx" /* webpackChunkName: "component---src-pages-open-verification-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-open-template-tsx": () => import("./../../../src/templates/open-template.tsx" /* webpackChunkName: "component---src-templates-open-template-tsx" */),
  "component---src-templates-static-template-tsx": () => import("./../../../src/templates/static-template.tsx" /* webpackChunkName: "component---src-templates-static-template-tsx" */)
}

